.main-footer {
    position: fixed;
    bottom:0px;
    width:100%;
}

button {
    margin-left: 0px;
}

.table-actions {
    width: 150px;
}

.info-box {
    margin-bottom: 0.50rem;
}

.info-box .info-box-icon {
    border-radius: 0;
}

.info-box-content {
    background-color: #007fff;
    color:white; 
}
.info-box .info-box-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    line-height: 120%;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0px 0px 0px 0px;
}
.bg-primary {
    background-color: #007fff !important
}

.bg-success {
    background-color: #5cb85c !important;
}

/*Menu do perfil*/
.dropdown-menu-lg {
    max-width: 400px;
    min-width: 306px;
    padding: 0;
}

/*Email e senha loguin*/
.MuiInputBase-input {
    background: #ffffff !important;
}
.MuiOutlinedInput-input {
    border-radius: 4px;
}
.MuiButton-contained {
    color: #0288d1 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000000 !important;
    border-width: 2px;
}
.MuiFormLabel-root.Mui-focused {
    color: #000000 !important;
}
.MuiInputLabel-root {
    display: block;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    background-color: #ffffff;
    border-radius: 3px;
}

.wrapper {
	background-color: #fc0000!important;
}

.login-box-body {
	background-color: #eee;
}

.login-box button {
	margin-left: 0px;
}

.login-box a:hover {
	cursor: pointer;
}

